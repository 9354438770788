.card .card-body.TableContainer
  padding: 0

.my-dropdown-button
  .dropdown-menu.show
    display: block !important
    white-space: nowrap
    overflow-x: auto
    // max-height: 400px
    // overflow-y: auto

  .my-dropdown-grid
    display: inline-flex
    flex-direction: row
    flex-wrap: nowrap
    gap: 15px

  .my-dropdown-column
    display: inline-flex
    flex-direction: column
    min-width: 150px
